<template>
  <div>
    <gamecateview />

    <game_page :m_show_id="'main_casino'" />
    <game_page :m_show_id="'main_slot'" />
    <!-- <game_page :m_show_id="'sport'" /> -->
    <!-- <game_page :m_show_id="'lotto'" /> -->
    <game_page :m_show_id="'fish'" />

    <rank />
  </div>
</template>

<script>
import Cookies from "js-cookie";
import game_page from "./game_page.vue";
import gamecateview from "./game_category.vue";
import rank from "./rank.vue";
export default {
  name: "main",
  data() {
    return {
      open_intro: false,
      code: Cookies.get("code"),
    };
  },
  computed: {
    g_type() {
      if (this.$route.params.type != null) {
        if (this.$route.params.type.indexOf("A1-") != -1)
          return this.$route.params.type.split("-")[1];
        else return this.$route.params.type;
      } else return "";
    },
  },
  methods: {
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
  },
  created() {},
  watch: {},
  mounted() {},
  components: {
    game_page,
    gamecateview,
    rank,
  },
};
</script>
