<template>
  <div
    class="wc-PageView_ContentContainer wc-LegacyPage_ContentContainerBankBalance wc-ModulePageChangePageData_ContentContainer mt-4"
    :class="sub_wall == 'subwallet' ? 'pb-0' : ''"
  >
    <div>
      <div>
        <section data-section="sba-balances">
          <div class="balanceLayout">
            <div class="sba-container">
              <div class="sba-balance sports-balance">
                <div data-section="SbaSports-balance-total">
                  <div class="balance-row sports-header">
                    <div class="balance-column withdraw-header">
                      <div>{{ $t("main_acc") }}</div>
                    </div>

                    <div class="balance-column withdraw-value">
                      <div>{{ balance }} {{ $t("text_tail_bal") }}</div>
                    </div>
                  </div>

                  <div class="buttons-row" v-if="sub_wall != 'subwallet'">
                    <div
                      class="button-links btn-deposit rounded-5"
                      @click="this.$emit('selectid', 'deposit')"
                    >
                      <div class="fn-btn-deposit">{{ $t("deposit") }}</div>
                    </div>

                    <div
                      class="button-links btn-withdraw rounded-5"
                      @click="this.$emit('selectid', 'withdraw')"
                    >
                      <div class="fn-btn-withdrawal">{{ $t("withdrawl") }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Home",
  props: { sub_wall: String },
  computed: {
    ...mapGetters(["balance"]),
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getBalance"]),
    getBalance1: function () {
      let param = {
        action: "game_to_LOBBY",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param);

      this.getBalance();
    },
  },
  created() {
    this.getBalance1();
  },
};
</script>
