<template>
  <div>
    <Loading :active="isLoading" :can-cancel="true">
      <img
        src="@/assets/img/main/35771931234507.564a1d2403b3a.gif"
        class="w-100"
        style="z-index: 9999"
      />
    </Loading>
    <router-view />
    <pop></pop>
    <ann></ann>
    <img
      @click="link()"
      v-if="
        $store.state.service.qq.qq != '' && $store.state.service.qq.qq != null
      "
      src="@/assets/img/main/pre_service.png"
      class="position-absolute end-0 service_img"
    />
    <!-- <preview v-if="Id == '' && flag_code_uid"></preview> -->
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Cookies from "js-cookie";
import pop from "./views/Home/pop.vue";
import ann from "./views/Home/announce.vue";
// import preview from "./views/preloading.vue";

export default {
  created() {
    document.title = this.$t("text_web_title");
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", this.$t("text_web_description"));
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", this.$t("text_web_keyword"));
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },

    flag_code_uid() {
      return Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
        ? true
        : false;
    },
  },
  components: {
    Loading,
    pop,
    ann,
    // preview,
  },
  methods: {
    link: function () {
      var url = "";
      url = this.$store.state.service.qq.qq;
      window.open(url);
    },
  },
};
</script>
