<template>
  <div
    class="wc-PageView_ContentContainer wc-LegacyPage_ContentContainerBankBalance wc-ModulePageChangePageData_ContentContainer"
    style="height: 105vh"
  >
    <div
      class="wc-PageView_ContentContainer wc-OpenAccountLegacyPage_ContentContainer"
    >
      <div>
        <!-- 存款頁面 -->
        <div>
          <div v-if="!flag_pw">
            <div
              class="d-flex justify-content-center align-items-center mt-xl-5 flex-wrap"
            >
              <p class="me-2 mb-0 mt-2" style="white-space: nowrap">
                {{ $t("reg_trade_pw_title") }}
              </p>

              <div
                class="d-flex justify-content-start align-items-start flex-column mt-2"
              >
                <div class="w-100 d-flex position-relative">
                  <input
                    :type="flag_eye2 ? 'text' : 'password'"
                    :placeholder="this.$t('reg_trade_pw_tips')"
                    v-model="trade_pw"
                    minlength="4"
                    maxlength="6"
                    class="ps-2"
                  />
                  <span
                    class="validating-icon"
                    @click="flag_eye2 = !flag_eye2"
                    style="position: absolute; right: 15px; top: 6px"
                  >
                    <img
                      :src="
                        flag_eye2
                          ? require('@/assets/img/main/open_eye.png')
                          : require('@/assets/img/main/close_eye.png')
                      "
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 text-center">
              <button
                type="submit"
                class="border-0 main_color bg_green_btn p-2 w-50 mt-3"
                @click="check_trade_pw"
              >
                {{ $t("shared_comfirm") }}
              </button>
            </div>
          </div>

          <div v-if="flag_pw">
            <div id="credit_pa">
              <div class="payment_banner">
                <div class="payment_container">
                  <div class="payment-method-selector-container">
                    <swiper
                      :slidesPerView="3"
                      :spaceBetween="15"
                      :modules="modules"
                      :navigation="swiper_options.navigation"
                      :breakpoints="swiper_options.breakpoints"
                    >
                      <swiper-slide
                        v-for="(l, index) in list"
                        :key="index"
                        style="text-align: center; margin-right: 0px !important"
                      >
                        <div
                          class="payment-method"
                          :class="
                            index == this.ChooseIndex ? 'payment_selected' : ''
                          "
                          @click="onChange(index)"
                        >
                          <item :cname="l.cname" :mpkidx="l.mpkidx" />
                        </div>
                      </swiper-slide>
                    </swiper>
                  </div>
                </div>
              </div>
              <div
                class="usdt_page"
                :class="this.ChooseName != '银行转帐' ? '' : 'Hidden'"
              >
                <div
                  class="deposit_amount w-100 d-flex"
                  @click="click_input_payment()"
                >
                  <input
                    type="text"
                    :placeholder="this.$t('deposit_amt')"
                    v-model="payment"
                    :class="flag_pro ? 'text-end' : 'text-center'"
                    id="ref_payment"
                  />
                  <span
                    v-show="flag_pro"
                    class="flexiField_AditionalInfo fw-bolder"
                    >+{{ fee }}
                  </span>
                  <span class="deposit_cur">
                    {{ $t("cur") }}
                  </span>
                </div>
                <p
                  class="mt-2 mb-1"
                  v-show="flag_pay_error"
                  style="color: #dc3545"
                >
                  {{ $t("error_deposit_tips") }}
                </p>
                <div v-show="this.feerate != 0" class="form-check mt-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckIndeterminate"
                    v-model="checkchoice"
                  />
                  <label class="form-check-label" for="flexCheckIndeterminate">
                    {{ title }}
                  </label>
                </div>

                <p class="mt-2 mb-1">{{ $t("deposit_min") }}：{{ Min }}</p>
                <p class="">{{ $t("deposit_max") }}：{{ Max }}</p>
                <div class="col-12 text-center">
                  <button
                    type="submit"
                    class="border-0 main_color bg_green_btn p-2 w-50 mt-3"
                    @click="deposit_tmp"
                    :disabled="flag_pay_error"
                    :style="
                      flag_pay_error
                        ? 'cursor: no-drop;background-color: #CCC;'
                        : ''
                    "
                  >
                    {{ $t("deposit_btn2") }}
                  </button>
                </div>
                <!-- <p class="usdt_page_word">
                  {{ $t("deposit_str4") }}
                </p> -->
              </div>
              <div
                class="usdt_page"
                :class="this.ChooseName == '银行转帐' ? '' : 'Hidden'"
              >
                <div class="d-flex justify-content-center align-items-start">
                  <p class="me-2 mb-0 mt-2" style="white-space: nowrap">
                    {{ $t("deposit_amt") }}
                  </p>

                  <div
                    class="d-flex justify-content-start align-items-start flex-column"
                    style="flex: 1"
                  >
                    <div
                      class="deposit_amount w-100 d-flex"
                      @click="click_input_payment()"
                    >
                      <input
                        type="text"
                        :placeholder="this.$t('deposit_amt')"
                        v-model="payment"
                        :class="flag_pro ? 'text-end' : 'text-center'"
                        id="ref_payment"
                      />
                      <span
                        v-show="flag_pro"
                        class="flexiField_AditionalInfo fw-bolder"
                        >+{{ fee }}
                      </span>
                      <span class="deposit_cur">
                        {{ $t("cur") }}
                      </span>
                    </div>

                    <p
                      class="mt-2 mb-1"
                      v-show="flag_pay_error"
                      style="color: #dc3545"
                    >
                      {{ $t("error_deposit_tips") }}
                    </p>
                    <p class="mt-2 mb-1">
                      {{ $t("deposit_tips").replaceAll("aa", this.Min) }}
                    </p>
                  </div>
                </div>

                <p class="mt-4">{{ $t("text_deposit1") }}</p>
                <p>
                  {{ $t("text_deposit2") }}： <span>{{ bankname }}</span>
                </p>
                <p>
                  {{ $t("add_Card") }}： <span>{{ cardno }}</span
                  ><svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="width: 30px; height: 17px; cursor: pointer"
                    @click="Copy"
                    ref="copy"
                  >
                    <path
                      d="M10.5333 4.66667H8.46662V2.20001C8.4648 1.67607 8.27043 1.17415 7.92588 0.803663C7.58133 0.433179 7.11455 0.22418 6.62728 0.222229H2.03928C1.55202 0.22418 1.08523 0.433179 0.740684 0.803663C0.396135 1.17415 0.201766 1.67607 0.199951 2.20001V7.13334C0.201766 7.65728 0.396135 8.1592 0.740684 8.52968C1.08523 8.90017 1.55202 9.10917 2.03928 9.11112H4.33328V11.3333C4.33328 11.9227 4.55102 12.4879 4.9386 12.9047C5.32617 13.3214 5.85184 13.5556 6.39995 13.5556H10.5333C11.0814 13.5556 11.6071 13.3214 11.9946 12.9047C12.3822 12.4879 12.6 11.9227 12.6 11.3333V6.8889C12.6 6.29953 12.3822 5.73429 11.9946 5.31755C11.6071 4.9008 11.0814 4.66667 10.5333 4.66667V4.66667ZM4.33328 6.8889V7.62964H2.03928C1.97867 7.62964 1.91865 7.6168 1.86265 7.59186C1.80666 7.56692 1.75577 7.53036 1.71292 7.48427C1.67006 7.43819 1.63606 7.38348 1.61286 7.32326C1.58967 7.26305 1.57773 7.19852 1.57773 7.13334V2.20001C1.57773 2.06838 1.62636 1.94215 1.71292 1.84907C1.79947 1.756 1.91687 1.70371 2.03928 1.70371H6.62728C6.7497 1.70371 6.86709 1.756 6.95365 1.84907C7.04021 1.94215 7.08884 2.06838 7.08884 2.20001V4.66667H6.39995C5.85184 4.66667 5.32617 4.9008 4.9386 5.31755C4.55102 5.73429 4.33328 6.29953 4.33328 6.8889V6.8889Z"
                      fill="#495A71"
                    ></path>
                  </svg>
                </p>
                <p>
                  {{ $t("reg_canme_title") }}： <span>{{ bankusername }}</span>
                </p>

                <!-- <div v-show="this.feerate != 0" class="form-check mt-2">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckIndeterminate"
                    v-model="checkchoice"
                  />
                  <label class="form-check-label" for="flexCheckIndeterminate">
                    {{ title }}
                  </label>
                </div> -->

                <!-- <div class="d-flex justify-content-center align-items-start">
                  <p class="me-2 mb-0 mt-2" style="white-space: nowrap">
                    {{ $t("deposit_bonus") }}
                  </p>
                  <select class="form-select" v-model="deposit_bonuschoice">
                    <option v-for="t in deposit_bonus" :key="t" :value="t">
                      {{ t }}
                    </option>
                  </select>
                </div> -->

                <div class="col-12 text-center">
                  <button
                    type="submit"
                    class="border-0 main_color bg_green_btn p-2 w-50 mt-3"
                    @click="banksubmit"
                    :disabled="flag_pay_error"
                    :style="
                      flag_pay_error
                        ? 'cursor: no-drop;background-color: #CCC;'
                        : ''
                    "
                  >
                    {{ $t("deposit_btn2") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <newWindow
      :URL="newWindowurl"
      :PAGE="'Deposit'"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import { reactive } from "vue";
import Cookies from "js-cookie";
//引入swiper
import SwiperCore, {
  Autoplay,
  Pagination,
  EffectCoverflow,
  Navigation,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.scss";
import newWindow from "./iframe.vue";
//设置swiper
SwiperCore.use([Autoplay, Pagination, EffectCoverflow, Navigation]);
import item from "./Home/depositItem";

export default {
  name: "Home",
  setup() {
    //指定swiper的设置
    let swiper_options = reactive({
      navigation: {
        nextElRef: ".swiper-button-next",
        prevElRef: ".swiper-button-prev",
      },
      breakpoints: {
        // when window width is >= 200px
        200: {
          slidesPerView: 2,
        },
        // when window width is >= 1200px
        1200: {
          slidesPerView: 3,
        },
      },
    });

    return {
      swiper_options,
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    item,
    newWindow,
  },
  computed: {},
  data() {
    return {
      list: [],
      payment: 0,
      ChooseIndex: 0,
      ChooseName: "银行转帐",
      capital: "",
      Max: 0,
      Min: 0,
      feerate: 0,
      feemax: 0,
      feemin: 0,
      fee: 0,
      mpkidx: "",
      newWindowurl: "",
      cname: "",
      cardno: "",
      flag_pro: false,
      checkchoice: [],
      title: this.$t("text_coll_deposit_time1"),
      is_first: "N",
      flag_pay_error: false,
      bankname: "",
      bankusername: "",
      flag_pw: false,
      trade_pw: "",
      flag_eye2: false,
      deposit_bonuschoice: this.$t("deposit_bonus3"),
      deposit_bonus: [
        this.$t("deposit_bonus1"),
        this.$t("deposit_bonus2"),
        this.$t("deposit_bonus3"),
      ],
    };
  },
  methods: {
    Choose: function () {
      let param = {
        action: "choose",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          withdrawpw: this.trade_pw,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var str = "";
        try {
          // if (res.status == "success") {
          this.list = res["data"].filter((v) => {
            if (!str.includes(v.cname)) {
              str = v.cname + ",";
              return v;
            }
          });
          this.onChange(0);

          this.flag_pw = true;
          // } else this.$MSG.API_popmsg(res, "", "warning");
        } catch {
          this.$MSG.API_popmsg(res, "wallet", "warning");
        }
      });
    },
    // deposit_tmp: function () {
    //   let max, min, money;
    //   max = parseInt(this.Max.replaceAll(",", ""));
    //   min = parseInt(this.Min.replaceAll(",", ""));
    //   money = parseFloat(this.payment.replaceAll(",", ""));
    //   if (money >= min && money <= max) {
    //     let param = {
    //       action: "deposit_tmp",
    //       body: {
    //         uid: localStorage.getItem("uid"),
    //         session_code: Cookies.get("code"),
    //         amount: money,
    //         mpkidx: this.mpkidx,
    //         capital: this.capital,
    //         cname: this.cname,
    //         cardno: this.cardno,
    //         checkchoice: this.flag_pro,
    //       },
    //     };
    //     this.$store.dispatch("getpost", param).then((res) => {
    //       if (res.status == "success") {
    //         this.newWindowurl = res.msg;
    //       } else {
    //         this.$MSG.API_popmsg(res.msg, "", "warning");
    //       }
    //     });
    //   } else {
    //     this.$MSG.API_popmsg(
    //       this.$t("deposit_min_tips") +
    //         "  " +
    //         this.Min +
    //         "," +
    //         this.$t("deposit_max_tips") +
    //         "  " +
    //         this.Max +
    //         ".",
    //       "",
    //       "error"
    //     );
    //   }
    // },
    banksubmit: function () {
      let max, min, money;
      max = parseInt(this.Max.replaceAll(",", ""));
      min = parseInt(this.Min.replaceAll(",", ""));
      money = parseFloat(this.payment.replaceAll(",", ""));
      if (money >= min && money <= max) {
        let param = {
          action: "banksubmit",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            payment: money,
            mpkidx: this.mpkidx,
            checkchoice: this.deposit_bonuschoice,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.$MSG.API_popmsg(res.msg, "", "success");
          } else {
            this.$MSG.API_popmsg(res.msg, "", "warning");
          }
        });
      } else {
        this.$MSG.API_popmsg(
          this.$t("deposit_min_tips") +
            "  " +
            this.Min +
            "," +
            this.$t("deposit_max_tips") +
            "  " +
            this.Max +
            ".",
          "",
          "error"
        );
      }
    },
    onChange: function (e) {
      this.ChooseIndex = e;
      var c = this.list[e];
      // this.ChooseName = c.cname;
      // this.mpkidx = c.mpkidx;
      this.capital = c.limit;
      this.feerate = c.feerate;
      this.feemax = c.feemax;
      this.feemin = c.feemin;
      this.is_first = c.is_first;
      if (this.is_first == "N") this.title = this.$t("text_coll_deposit_time2");
      if (
        parseFloat(this.payment.toString().replaceAll(",", "")) >=
        parseFloat(this.feemin)
      ) {
        this.fee =
          this.feerate *
            parseFloat(this.payment.toString().replaceAll(",", "")) *
            0.01 >
          this.feemax
            ? this.feemax
            : this.feerate *
              parseFloat(this.payment.toString().replaceAll(",", "")) *
              0.01;
      } else this.fee = 0;
      this.Min = this.filter_number(parseInt(this.capital.split(",")[0]));
      this.Max = this.filter_number(parseInt(this.capital.split(",")[1]));
      this.BankChoose();
    },
    BankChoose: function () {
      let param = {
        action: "bankchoose",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        try {
          this.mpkidx = res["data"][0]["mpkidx"];
          this.bankname = res["data"][0]["bankname"];
          this.cardno = res["data"][0]["bankno"];
          this.bankusername = res["data"][0]["cname"];
        } catch {
          this.$MSG.API_popmsg(res, "wallet", "warning");
        }
      });
    },
    click_input_payment: function () {
      document.getElementById("ref_payment").focus();
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
    filter_number: function (value) {
      if (!value) return "";
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
    Copy: function () {
      var content = this.cardno;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    check_trade_pw: function () {
      if (this.checkform()) {
        this.Choose();
      } else {
        this.$MSG.API_popmsg(
          this.$t("reg_trade_pw_title") + " " + this.$t("error_required"),
          "",
          "error"
        );
      }
    },
    checkform: function () {
      if (this.trade_pw == null || this.trade_pw == "") {
        return false;
      } else return true;
      // var rules = /^([0-9]{4,6})+$/;
      // if (!rules.test(this.trade_pw)) {
      // this.$MSG.API_popmsg(
      //   this.$t("Member_registerTradePasswordPatternErrMessage"),
      //   "",
      //   "error"
      // );
      //   return false;
      // } else return true;
    },
  },
  watch: {
    payment: function (e) {
      this.payment = e.replaceAll(",", "");
      if (!Number(this.payment) || this.payment % 10000 != 0) {
        this.flag_pay_error = true;
      } else this.flag_pay_error = false;
      if (parseFloat(this.payment) >= parseFloat(this.feemin)) {
        this.fee =
          this.feerate * this.payment * 0.01 > this.feemax
            ? this.feemax
            : this.feerate * this.payment * 0.01;
      } else this.fee = 0;
      this.payment = this.filter_number(this.payment);
    },
    checkchoice() {
      if (this.checkchoice.length > 0) this.flag_pro = true;
      else this.flag_pro = false;
    },
  },
  created() {},
};
</script>
<style>
#credit_pa .swiper-button-prev,
#credit_pa .swiper-button-next {
  display: block !important;
}

#credit_pa .swiper-button-next:after,
#credit_pa .swiper-rtl .swiper-button-prev:after {
  content: "next";
  font-size: 18px;
  color: #000;
}

#credit_pa .swiper-button-prev:after,
#credit_pa .swiper-rtl .swiper-button-next:after {
  content: "prev";
  font-size: 18px;
  color: #000;
}
</style>
