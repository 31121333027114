<template>
  <!-- 侧边的nav -->
  <div>
    <div class="prewindow_div" @click="hide_nav()" :class="pre_top1_div"></div>
    <div
      class="collapse navbar-collapse"
      id="navbarNav"
      :class="flag_nav ? 'show' : ''"
    >
      <div class="icon-wrapper" @click="hide_nav()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#8d999f"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </div>
      <!-- <div class="d-flex w-100 left_top_header align-items-center">
      <button class="left-button border-0" @click="hide_nav()">
        <svg fill="#b1bad3" viewBox="0 0 64 64" class="svg-icon" style="">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0h64v13H0V0Zm0 25.5h64v13H0v-13ZM64 51H0v13h64V51Z"
          ></path>
        </svg>
      </button>
      <div
        class="d-flex align-items-center w-100 left_top_btn"
        style="gap: 0.5rem"
      >
        <div
          class="left-fast-button c-pointer"
          @click="this.$router.push('/slot/hot')"
        >
          <div
            class="bg-casino-main"
            :class="Id == 'main_sport' ? 'bg-casino-off' : 'bg-casino-on'"
            @click="newmain_view(), false_nav()"
          >
            <span style="text-transform: uppercase">
              {{ $t("text_main3") }}</span
            >
          </div>
        </div>
        <div
          class="left-fast-button c-pointer"
          @click="this.$router.push('/main_sport')"
        >
          <div
            class="bg-casino-main"
            :class="Id == 'slot' ? 'bg-sport-off' : 'bg-sport-on'"
            @click="click_sport(), false_nav()"
          >
            <span style="text-transform: uppercase">
              {{ $t("text_main4") }}</span
            >
          </div>
        </div>
      </div>
    </div> -->

      <div class="left-new-logo">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <p class="text-gold fw-bolder left-new-span">{{ uid }}</p>

      <div class="d-flex flex-column w-100 navbar-div-nav" id="scro_left">
        <ul class="navbar-nav flex-column mb-5">
          <!-- <div class="left-top-bg p-2">
            <li @click="click_pro(), false_nav()" class="left-top-pro">
              <div class="d-flex align-items-center">
                <img
                  src="@/assets/img/left/menu_ic_spgem.png"
                  :title="$t('faq_5')"
                />
                <a class="nav-link fw-bold">{{ $t("faq_5") }}</a>
              </div>
            </li>
            <li @click="open_wheel_fun(), false_nav()" class="left-top-pro">
              <div class="d-flex align-items-center">
                <img
                  src="@/assets/img/left/menu_ic_lucky.png"
                  :title="$t('text_wheel')"
                />
                <a class="nav-link fw-bold">{{ $t("text_wheel") }}</a>
              </div>
            </li>

            <li @click="open_sign_fun(), false_nav()" class="left-top-pro">
              <div class="d-flex align-items-center">
                <img
                  src="@/assets/img/left/menu_ic_bell.png"
                  :title="$t('text_sign')"
                />
                <a class="nav-link fw-bold">{{ $t("text_sign") }}</a>
              </div>
            </li>
          </div> -->
          <li @click="click_pro(), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_spgem.png"
              :title="$t('faq_5')"
            />
            <a class="nav-link fw-bold">{{ $t("faq_5") }}</a>
          </li>
          <li @click="this.$router.push('/slot/casino'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-live.png"
              :title="$t('text_home')"
            />
            <a class="nav-link fw-bold">{{ $t("home_text6") }}</a>
          </li>
          <li @click="this.$router.push('/slot/slot'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-slot.png"
              :title="$t('text_home')"
            />
            <a class="nav-link fw-bold">{{ $t("home_text7") }}</a>
          </li>
          <!-- <li @click="this.$router.push('/slot/sport'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-sport.png"
              :title="$t('text_home')"
            />
            <a class="nav-link fw-bold">{{ $t("text_sport") }}</a>
          </li> -->
          <!-- <li @click="this.$router.push('/slot/lotto'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-lotto.png"
              :title="$t('text_home')"
            />
            <a class="nav-link fw-bold">{{ $t("text_lotto") }}</a>
          </li> -->
          <li @click="this.$router.push('/slot/fish'), false_nav()">
            <img
              src="@/assets/img/game_category/icon-fish.png"
              :title="$t('text_home')"
            />
            <a class="nav-link fw-bold">{{ $t("text_main12") }}</a>
          </li>
          <!-- 
          <li @click="go_home(), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_1_home.png"
              :title="$t('text_home')"
            />
            <a class="nav-link fw-bold">{{ $t("text_home") }}</a>
          </li>
          <li @click="this.$router.push('/VIP'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_2_member.png"
              :title="$t('left_menu_text3')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text3") }}</a>
          </li>
          <li @click="this.$router.push('/BO/invite'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_3_share.png"
              :title="$t('left_menu_text4')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text4") }}</a>
          </li>
          <li @click="this.$router.push('/ME/wallet'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_4_recharge.png"
              :title="$t('text_recharge')"
            />
            <a class="nav-link fw-bold">{{ $t("text_recharge") }}</a>
          </li>
          <li @click="this.$router.push('/ME/myacc'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_5_myaccount.png"
              :title="$t('myacc')"
            />
            <a class="nav-link fw-bold">{{ $t("myacc") }}</a>
          </li>
          <li @click="this.$router.push('/ME/history'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_6_record.png"
              :title="$t('left_menu_text5')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text5") }}</a>
          </li>
          <li @click="this.$router.push('/ME/activity'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_7_myactivity.png"
              :title="$t('myactivity')"
            />
            <a class="nav-link fw-bold">{{ $t("myactivity") }}</a>
          </li>
          <li @click="this.$router.push('/ME/message'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_8_messages.png"
              :title="$t('left_menu_text6')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text6") }}</a>
          </li>
          <li @click="this.$router.push('/VIP_intro'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_9_vipbenefits.png"
              :title="$t('left_menu_text7')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text7") }}</a>
          </li>
          <li
            @click="link('service')"
            v-if="
              $store.state.service.qq.qq != '' &&
              $store.state.service.qq.qq != null
            "
          >
            <img
              src="@/assets/img/left/menu_ic_10_customerservice.png"
              :title="$t('left_menu_text8')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text8") }}</a>
          </li>
          <li
            @click="link('telegram')"
            v-if="
              $store.state.service.qq.telegram != '' &&
              $store.state.service.qq.telegram != null
            "
          >
            <img
              src="@/assets/img/left/menu_ic_11_tg.png"
              :title="$t('left_menu_text9')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text9") }}</a>
          </li>
          <li
            @click="link('fb')"
            v-if="
              $store.state.service.qq.fb != '' &&
              $store.state.service.qq.fb != null
            "
          >
            <img
              src="@/assets/img/left/menu_ic_12_fb.png"
              :title="$t('left_menu_text10')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text10") }}</a>
          </li> -->
          <li
            @click="this.$store.commit('isann', true), false_nav()"
            class="mt-5"
          >
            <img
              src="@/assets/img/left/menu_ic_billboard.png"
              :title="$t('notice')"
            />
            <a class="nav-link fw-bold">{{ $t("notice") }}</a>
          </li>
          <!-- <li @click="this.$router.push('/help/E'), false_nav()">
            <img
              src="@/assets/img/left/menu_ic_partner.png"
              :title="$t('footer_e')"
            />
            <a class="nav-link fw-bold">{{ $t("footer_e") }}</a>
          </li> -->
          <li v-if="code != null" @click="logout">
            <img src="@/assets/img/left/logout.png" :title="$t('logout')" />
            <a class="nav-link fw-bold">{{ $t("logout") }}</a>
          </li>
          <li class="left-lang">
            <Lang class="left-lang w-100" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
import Lang from "./Home/language.vue";
import {
  main_casino_on,
  main_sport_on,
  main_casino_off,
  main_sport_off,
} from "@/components/Img/img";

export default {
  name: "maintop1",
  data() {
    return {
      code: Cookies.get("code"),
      uid: localStorage.getItem("uid"),
      shareurl: "",
      sharecode: "",
      img1: require("@/assets/img/left/menu_ic_1_home.png"),
      img2: require("@/assets/img/left/menu_ic_2_member.png"),
      img3: require("@/assets/img/left/menu_ic_3_share.png"),
      img4: require("@/assets/img/left/menu_ic_4_recharge.png"),
      img5: require("@/assets/img/left/menu_ic_5_myaccount.png"),
      img6: require("@/assets/img/left/menu_ic_6_record.png"),
      img7: require("@/assets/img/left/menu_ic_7_myactivity.png"),
      img8: require("@/assets/img/left/menu_ic_8_messages.png"),
      img9: require("@/assets/img/left/menu_ic_9_vipbenefits.png"),
      img10: require("@/assets/img/left/menu_ic_billboard.png"),
      img11: require("@/assets/img/left/menu_ic_partner.png"),
      img12: require("@/assets/img/left/menu_ic_10_customerservice.png"),
      img13: require("@/assets/img/left/menu_ic_11_tg.png"),
      img14: require("@/assets/img/left/menu_ic_12_fb.png"),
      img15: require("@/assets/img/left/logout.png"),
      img_casino: JSON.parse(JSON.stringify(main_casino_on))[
        localStorage.getItem("locale")
      ],
      img_sport: JSON.parse(JSON.stringify(main_sport_on))[
        localStorage.getItem("locale")
      ],
      img_casino_off: JSON.parse(JSON.stringify(main_casino_off))[
        localStorage.getItem("locale")
      ],
      img_sport_off: JSON.parse(JSON.stringify(main_sport_off))[
        localStorage.getItem("locale")
      ],
    };
  },
  components: {
    Lang,
  },
  computed: {
    ...mapGetters([
      "flag_nav",
      "balance",
      "flag_code",
      "flag_wheel",
      "flag_sign",
      "flag_extra",
    ]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    pre_top1_div: function () {
      if (window.innerWidth <= 1199 && this.flag_nav) {
        return "d-block";
      } else return "d-none";
    },
  },
  methods: {
    ...mapActions(["getBalance", "logout_public"]),
    logout: function () {
      this.logout_public();
      this.$router.push("/");
      window.location.reload();
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    false_nav: function () {
      if (window.innerWidth > 768) this.$store.commit("flagnav", this.flag_nav);
      else this.$store.commit("flagnav", false);
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.shareurl = res["url"];
          this.sharecode = res["ref"];
        }
      });
    },
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    go_home() {
      this.$router.push("/");
    },
    change_img(id) {
      return require("@/assets/img/left/hover/" + id + ".png");
    },
    change_img_nothover(id) {
      return require("@/assets/img/left/" + id + ".png");
    },
    newmain_view: function () {
      this.$router.push("/slot/hot");
    },
    click_sport: function () {
      this.$router.push("/main_sport");
    },
    click_pro: function () {
      this.$router.push("/promo");
    },
    open_wheel_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        // this.$store.commit("flag_wheel", true);
        this.$store.commit("flag_wheel", false);
      }
    },
    open_sign_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        // this.$store.commit("flag_sign", true);
        this.$store.commit("flag_sign", false);
      }
    },
  },
  created() {
    if (this.code != "") {
      this.getBalance();
      this.share();
    }
    if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
  },
  watch: {},
};
</script>
